import { ApolloProvider } from '@apollo/client';
import React from 'react';

import { AuthStatus, useReefAuthContext } from '../reefAuthContext';
import { usePublicReefApolloClient, useReefApolloClient } from './hooks';

interface ReefApolloProviderProps {
  rootGraphqlUri: string;
  children: React.ReactNode;
}

export const ReefApolloProvider: React.FC<ReefApolloProviderProps> = ({
  rootGraphqlUri,
  children,
}) => {
  const authorizedClient = useReefApolloClient({ graphqlUri: `${rootGraphqlUri}graphql` });
  const publicClient = usePublicReefApolloClient({ graphqlUri: `${rootGraphqlUri}publicgraphql` });

  const [authState] = useReefAuthContext();

  return (
    <ApolloProvider
      client={authState.status === AuthStatus.SignedIn ? authorizedClient : publicClient}
    >
      {children}
    </ApolloProvider>
  );
};
