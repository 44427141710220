import type { ActivityStatus as ApiActivityStatus } from '../graphql/generated';
import { SprintStatus } from '../graphql/generated';
import type { Account } from './account';
import type { UUID } from './uuid';

/**
 * A basic link object that can be represented in the UI.
 */
export interface Link {
  id: UUID;
  title: string;
  url: string;
}

export interface Action {
  id: UUID;
  title: string;
  /**
   * Determines the positional order of the action when selecting from a list of actions.
   */
  rank: number;
  /**
   * A string representation of the entity that created this action.
   */
  author: string;
  /**
   * A list of links associated with this action.
   */
  links: Link[];
  /**
   * If `true`, the action is published and available for general use.
   */
  isPublished: boolean;
  /**
   * The date this action was last updated on.
   */
  lastUpdated: Date | undefined;
}

export type ActivityStatus = Exclude<ApiActivityStatus, ApiActivityStatus.NotActive>;

export interface Activity {
  id: UUID;
  status: ActivityStatus;
  account: Account;
  action: Action;
  assignee: { id: UUID; name: string };
  note: string | null;
  isTerminal: boolean;
}

export interface UserActivity extends Activity {
  sprint: SprintDashboardElement;
}

export interface Objective {
  id: UUID;
  title: string;
  /**
   * Determines the positional order of the objective when selecting from a list of objectives.
   */
  rank: number;
  description?: string | null;
  hasActions: boolean;
  maxActionRank: number;
  actions?: Pick<Action, 'id' | 'title' | 'links' | 'rank'>[];
}

interface SprintObjective {
  id: UUID;
  title: string;
}

interface SprintSummary {
  completionPercentage: number | null;
  playbookTitle: string | null;
  totalAccounts: number | null;
  totalActions: number | null;
  totalActivities: number | null;
}

export interface Sprint extends SprintSummary {
  id: UUID;
  status: SprintStatus;
  name: string;
  start: Date | null;
  end: Date | null;
  objective: SprintObjective;
  activities: Activity[];
  createdBy: { id: UUID; name: string };
  isFollowing: boolean;
  following: SprintFollowing;
}

export interface SprintNavElement {
  id: UUID;
  status: SprintStatus;
  name: string;
  start: Date | null;
  end: Date | null;
}

export enum SprintFollowing {
  NotFollowing = 'NOT_FOLLOWING',
  FollowingOther = 'FOLLOWING_OTHER',
  FollowingSelf = 'FOLLOWING_SELF',
}

export interface SprintDashboardElement extends SprintNavElement, SprintSummary {
  createdBy: { id: UUID; name: string };
  isFollowing: boolean;
  following: SprintFollowing;
}

export const DEFAULT_SPRINT: Sprint = {
  id: '' as UUID,
  activities: [],
  completionPercentage: NaN,
  createdBy: { id: '' as UUID, name: '' },
  end: null,
  isFollowing: false,
  following: SprintFollowing.NotFollowing,
  name: '',
  objective: { id: '' as UUID, title: '' },
  playbookTitle: '',
  start: null,
  status: SprintStatus.Draft,
  totalAccounts: NaN,
  totalActions: NaN,
  totalActivities: NaN,
};

export enum CreateSprintStep {
  Objective = 0,
  Start = 1,
}

export type DraftActivity = Pick<Activity, 'account' | 'action'>;

export const ACTIVITY_STATUS_LABELS = {
  COMPLETE: 'Completed',
  IN_PROCESS: 'In Progress',
  NOT_ACTIVE: 'Not Active',
  NOT_STARTED: 'Not Started',
  REJECTED: 'Dismissed',
} as const;
