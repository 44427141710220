import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type {
  ActivityFieldsFragment,
  GetMyWorkActivitiesQuery,
  GetSprintPageQuery,
  SprintDashboardFieldsFragment,
  SprintNavFieldsFragment,
  UserActivityFieldsFragment,
  UserSprintDashboardFieldsFragment,
} from '../graphql/generated';
import {
  ActivityStatus,
  GetMySprintsDocument,
  GetMyWorkActivitiesDocument,
  GetMyWorkSprintsDocument,
  GetSprintPageDocument,
} from '../graphql/generated';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import {
  type Activity,
  type Sprint,
  type SprintDashboardElement,
  SprintFollowing,
  type SprintNavElement,
  type UserActivity,
} from '../models/sprint';
import type { UUID } from '../models/uuid';
import { transformDate } from '../utils';
import { getAccountFromAPIAccount } from './account';
import { useCurrentUserDetails } from './user';

export const transformSprintNav = <T extends SprintNavFieldsFragment>(item: T) =>
  ({
    id: item.id,
    end: transformDate(item.end, { localized: true }),
    name: item.name,
    start: transformDate(item.start, { localized: true }),
    status: item.status,
  }) satisfies SprintNavElement;

const getFollowingStatus = (
  creatorId: UUID,
  viewerId: UUID,
  isFollowing: boolean,
): SprintDashboardElement['following'] => {
  if (isFollowing) {
    return SprintFollowing.FollowingOther;
  }
  if (creatorId === viewerId) {
    return SprintFollowing.FollowingSelf;
  }
  return SprintFollowing.NotFollowing;
};
export const transformSprintDashboard = <T extends SprintDashboardFieldsFragment>(
  item: T,
  viewerId: UUID,
) =>
  ({
    ...transformSprintNav(item),
    completionPercentage: item.summary.activityCompletionPercentage,
    createdBy: { id: item.createdBy.id, name: item.createdBy.name },
    isFollowing: item.isFollowing,
    following: getFollowingStatus(item.createdBy.id, viewerId, item.isFollowing),
    playbookTitle: item.summary.objectiveTitle,
    totalAccounts: item.summary.numAccounts,
    totalActions: item.summary.numActions,
    totalActivities: item.summary.numActivities,
  }) satisfies SprintDashboardElement;

const transformUserSprintDashboard = <T extends UserSprintDashboardFieldsFragment>(item: T) =>
  ({
    ...transformSprintNav(item),
    completionPercentage: item.summary.activityCompletionPercentage,
    createdBy: { id: item.createdBy.id, name: item.createdBy.name },
    // User <-> Sprints do not have isFollowing populated
    isFollowing: false,
    following: SprintFollowing.NotFollowing,
    playbookTitle: item.summary.objectiveTitle,
    totalAccounts: item.summary.numAccounts,
    totalActions: item.summary.numActions,
    totalActivities: item.summary.numActivities,
  }) satisfies SprintDashboardElement;

const transformActivity = (
  item: ActivityFieldsFragment | UserActivityFieldsFragment,
  currencyCode: string,
): Activity => ({
  id: item.id,
  action: {
    id: item.action.id,
    title: item.action.title,
    rank: item.action.rank,
    author: item.action.createdBy?.name ?? 'Reef.ai',
    links: ('links' in item.action ? item.action.links : []).map((l) => ({
      id: l.id,
      title: l.title,
      url: l.url,
    })),
    lastUpdated: transformDate(item.action.lastUpdatedAt, { localized: true }),
    isPublished: item.action.isPublished,
  },
  assignee: { id: item.assignee.id, name: item.assignee.name },
  // TODO: [REEF-1292] if we resolve assignee, we wouldn't need to use this lookup memo
  status:
    // not-active activity is not supported in the UI, so we default it here
    item.status === ActivityStatus.NotActive ? ActivityStatus.NotStarted : item.status,
  account: getAccountFromAPIAccount(item.account, currencyCode),
  note: 'note' in item ? (item.note?.text ?? null) : null,
  isTerminal: item.status === ActivityStatus.Complete || item.status === ActivityStatus.Rejected,
});
export const transformSprintActivity = <T extends ActivityFieldsFragment>(
  item: T,
  currencyCode: string,
): Activity => transformActivity(item, currencyCode);

const transformUserSprintActivity = <T extends GetMyWorkActivitiesQuery['self']>(
  item: T extends { __typename?: 'User'; associated: { activeActivities: Array<infer U> } }
    ? U
    : never,
  currencyCode: string,
): UserActivity => ({
  ...transformActivity(item, currencyCode),
  sprint: transformUserSprintDashboard(item.sprint),
});

export const transformSprint = <T extends GetSprintPageQuery['node']>(
  item: T extends { __typename?: 'Sprint' } ? T : never,
  currencyCode: string,
  viewerId: UUID,
): Sprint => ({
  id: item.id,
  activities: item.activities.map((act) => transformSprintActivity(act, currencyCode)),
  completionPercentage: item.summary.activityCompletionPercentage,
  createdBy: { id: item.createdBy.id, name: item.createdBy.name },
  end: transformDate(item.end, { localized: true }),
  isFollowing: item.isFollowing,
  following: getFollowingStatus(item.createdBy.id, viewerId, item.isFollowing),
  name: item.name,
  objective: { id: item.objective.id, title: item.objective.title },
  playbookTitle: item.summary.objectiveTitle,
  start: transformDate(item.start, { localized: true }),
  status: item.status,
  totalAccounts: item.summary.numAccounts,
  totalActions: item.summary.numActions,
  totalActivities: item.summary.numActivities,
});

/**
 * Hook to get a sprint by it's id.
 * @param sprintId sprint to get
 * @returns result of a sprint query
 */
export const useSprint = (sprintId: UUID | undefined): Result<Sprint, ApolloError> => {
  const { user } = useCurrentUserDetails();
  const { currencyCode } = useUserSettingsContext();
  const variables = sprintId != null ? { id: sprintId } : undefined;
  const sprintResult = useResultFromQuery(
    useQuery(GetSprintPageDocument, { variables, skip: sprintId == null }),
  );

  return useMemo((): Result<Sprint, ApolloError> => {
    if (sprintId == null) {
      return { state: ResultType.NoValue, value: undefined };
    }

    if (sprintResult.state === ResultType.Value) {
      if (sprintResult.value.node.__typename === 'Sprint') {
        return {
          ...sprintResult,
          value: transformSprint(sprintResult.value.node, currencyCode, user.id),
        };
      }

      return { state: ResultType.NoValue, value: undefined };
    }

    return sprintResult;
  }, [currencyCode, sprintId, sprintResult, user.id]);
};

export const useMySprints = (): Result<SprintDashboardElement[], ApolloError> => {
  const mySprintsResult = useResultFromQuery(useQuery(GetMyWorkSprintsDocument));
  return useMemo((): Result<SprintDashboardElement[], ApolloError> => {
    if (mySprintsResult.state === ResultType.Value) {
      const { activeSprints, endedSprints } = mySprintsResult.value.self.associated;
      const value = [...activeSprints, ...endedSprints].map(transformUserSprintDashboard);
      return { state: mySprintsResult.state, value };
    }
    return mySprintsResult;
  }, [mySprintsResult]);
};

export const useMyActivities = (): Result<UserActivity[], ApolloError> => {
  const { currencyCode } = useUserSettingsContext();
  const myActivitiesResult = useResultFromQuery(useQuery(GetMyWorkActivitiesDocument));
  return useMemo((): Result<UserActivity[], ApolloError> => {
    if (myActivitiesResult.state === ResultType.Value) {
      const value = myActivitiesResult.value.self.associated.activeActivities.map((act) =>
        transformUserSprintActivity(act, currencyCode),
      );
      return { state: myActivitiesResult.state, value };
    }
    return myActivitiesResult;
  }, [currencyCode, myActivitiesResult]);
};

interface SprintLists {
  /**
   * The list of active sprints the user is participating in.
   */
  created: SprintNavElement[];
  /**
   * The list of completed sprints the user has participated in.
   */
  following: SprintNavElement[];
  completedSprints: SprintNavElement[];
  followingCompletedSprints: SprintNavElement[];
}

/**
 * Hook to get active and completed sprints for the drawer nav.
 * @returns result with value tuple of [ACTIVE, COMPLETED] sprints by status
 */
export const useSprintList = (): Result<SprintLists, ApolloError> => {
  const queryResult = useResultFromQuery(useQuery(GetMySprintsDocument));

  return useMemo(() => {
    if (queryResult.state === ResultType.Value) {
      const { mySprints, followingSprints, completedSprints, followingCompletedSprints } =
        queryResult.value.self.associated;

      return {
        ...queryResult,
        value: {
          created: mySprints.map(transformSprintNav),
          following: followingSprints.map(transformSprintNav),
          completedSprints: completedSprints.map(transformSprintNav),
          followingCompletedSprints: followingCompletedSprints.map(transformSprintNav),
        },
      };
    }

    return queryResult;
  }, [queryResult]);
};
