import type { ApolloClientOptions, NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { useRef } from 'react';

import { useReefFlags } from '../../hooks/flags';
import { AuthStatus, useReefAuthContext, useReefAuthService } from '../reefAuthContext';

const useApolloClientRef = (
  options: ApolloClientOptions<NormalizedCacheObject>,
): ApolloClient<NormalizedCacheObject> => {
  const { apolloDevtoolsEnabled } = useReefFlags();

  // Use a ref to track the Apollo Client instance
  const client = useRef<ApolloClient<NormalizedCacheObject> | null>(null);

  if (client.current == null) {
    client.current = new ApolloClient({
      ...options,
      connectToDevTools: apolloDevtoolsEnabled,
      defaultOptions: {
        watchQuery: { fetchPolicy: 'cache-and-network' },
        query: { fetchPolicy: 'network-only' },
      },
    });
  }

  return client.current;
};

interface UseReefApolloClientOptions {
  graphqlUri: string;
}

/**
 * Get a new apollo client for the public reef api.
 * @param root0 options
 * @param root0.graphqlUri uri for the root graphql api
 * @returns apollo client
 */
export function usePublicReefApolloClient({
  graphqlUri,
}: UseReefApolloClientOptions): ApolloClient<NormalizedCacheObject> {
  return useApolloClientRef({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      new RetryLink({ attempts: { max: 3 } }),
      new HttpLink({ uri: graphqlUri }),
    ]),
  });
}

/**
 * Get a new apollo client configured for the reef graphql api.
 * @param root0 options
 * @param root0.graphqlUri uri for the graphql api
 * @returns apollo client
 */
export function useReefApolloClient({
  graphqlUri,
}: UseReefApolloClientOptions): ApolloClient<NormalizedCacheObject> {
  const authService = useReefAuthService();
  const [authCtx, { logout }] = useReefAuthContext();

  return useApolloClientRef({
    cache: new InMemoryCache({
      typePolicies: {
        Account: { keyFields: ['id', 'lastUpdated'] },
        Client: { merge: true },
        UserAssociations: { merge: true },
        RawAccountData: { merge: true },
        ReefAccountData: { merge: true },
        Filter: {
          fields: {
            nodes: {
              merge(_, incoming) {
                return incoming; // Replace old data with new data
              },
            },
          },
        },
      },
    }),
    link: ApolloLink.from([
      setContext(async (_, ctx) => {
        // we need to get the current session on _every_ request so we can trigger
        // refreshing tokens. because this link gets built on every apollo request/connection
        // we want to ask for the token here and join that Observable with the `forward`
        // Observable result (by using a flatMap operation)
        //
        // Calling `getCurrentSession` won't necessarily trigger a cognito refresh call every time -
        // only when the access or id tokens in the auth session are expired
        const session = await authService.getCurrentSession();
        // if the user was previously signed-in, but we weren't able to refresh the
        // auth token then we should log them out (and kick them back to the login flow)
        if (session == null && authCtx.status === AuthStatus.SignedIn) {
          // TODO: [REEF-1176] navigate to "/login" when the user is auto-logged out
          await logout();
          return ctx;
        }
        return {
          ...ctx,
          headers: {
            Authorization: session?.getIdToken().getJwtToken(),
            Accept: 'charset=utf-8',
          },
        };
      }),
      new RetryLink({ attempts: { max: 3 } }),
      new HttpLink({ uri: graphqlUri }),
    ]),
  });
}
